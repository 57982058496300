<template>
  <div
    :data-product-id="product.id"
    class="product-card"
    @click="addEcommerce('click',product as IProduct)"
  >
    <div
      ref="productImg"
      class="product-card__media slider-images-navigation-button"
    >
      <template v-if="showSwiper">
        <nuxt-error-boundary @error="() => {}">
          <swiper
            :modules="[Navigation, Pagination, Virtual]"
            :loop="true"
            :navigation="true"
            :pagination="true"
            :virtual="imageIds.length > 3"
            :space-between="5"
          >
            <swiper-slide
              v-for="(imageId, index) in imageIds"
              :key="`product-image-${product.id}-${imageId}`"
            >
              <nuxt-link
                :to="product.url"
                @click.capture="clickLinkProduct"
              >
                <nuxt-img
                  :src="String(imageId)"
                  width="414px"
                  height="319px"
                  fit="default"
                  loading="lazy"
                  :alt="product.name"
                  :modifiers="{'border': 'default'}"
                />
                <div class="swiper-lazy-preloader"></div>
              </nuxt-link>
            </swiper-slide>
          </swiper>
        </nuxt-error-boundary>
      </template>

      <div
        v-else
        class="preload-plug"
      ></div>
    </div>
    <div class="product-card__body">
      <div class="product-card__name-parent">
        <div class="tooltip">
          <nuxt-link
            :to="product.url"
            class="tooltip__item product-card__name hover-decoration"
            @click.capture="clickLinkProduct"
          >
            <span v-html="product.name" />
          </nuxt-link>
          <div class="tooltip__hover">
            <span v-html="product.name" />
          </div>
        </div>
      </div>
      <div class="product-card__row-between">
        <div class="product-card__tags">
          <template
            v-for="(tag, index) in tags"
            :key="`tag-${index}`"
          >
            <TagItem
              :tag="tag"
              :is-product="true"
            />
          </template>
        </div>
        <div class="product-card__prices">
          <div
            v-if="priceOld"
            class="--old"
          >
            {{ priceOld }}
          </div>
          <div class="--current">{{ price }}</div>
        </div>
      </div>

      <template v-if="Boolean(colorsValue.length || sizeValue)">
        <div class="product-card__delimiter" />
      </template>

      <!-- Инфомарция по хар-тикам -->
      <div
        v-if="sizeValue"
        class="product-card__char-info"
      >
        <div class="label-icon">
          <img
            src="~/assets/img/svg/characteristics/size.svg"
            alt="size"
          />
        </div>
        <div class="label-text">Размер</div>
        <div class="value-text h5">{{ sizeValue }}</div>
        <template v-if="Boolean(sizeCount)">
          <TooltipSizes
            :sizes="sizeOtherValue"
            :product-url="product.url"
          >
            <div class="value-button">
              {{ sizeCount }}
            </div>
          </TooltipSizes>
        </template>
      </div>
      <div
        v-if="colorsValue.length"
        class="product-card__char-info mt"
      >
        <div class="label-icon">
          <img
            src="~/assets/img/svg/characteristics/color.svg"
            alt="color"
          />
        </div>
        <div class="label-text">Цвет</div>
        <div class="value-colors">
          <div
            v-for="(color, index) in colorsValue"
            :key="`product-char-color-${index}`"
          >
            <ColorItemForTooltip
              :color-item="color"
            />
            <div
              v-if="false"
              class="tooltip"
            >
              <div class="tooltip__item --color-item">
                <nuxt-img
                  :src="String(color.imageId)"
                  :alt="String(color.imageId)"
                  :width="40"
                  :height="40"
                  border="2px"
                />
              </div>
              <div class="tooltip__hover">
                <span v-html="color.value" />
              </div>
            </div>
          </div>
        </div>

        <template v-if="Boolean(colorsCount)">
          <TooltipColors
            :colors="colorsOtherValue"
            :product-url="product.url"
          >
            <div class="value-button">
              {{ colorsCount }}
            </div>
          </TooltipColors>
        </template>
      </div>
      <div class="add-toCart__mobile">
        <NuxtLink
          v-if="product.hasOptions"
          :to="product.url"
          class="btn btn-primary"
        >
          Выбрать комплектацию
        </NuxtLink>

        <AddToCartButtonWithCounter
          v-else
          :product="product as IProduct"
        />
      </div>
    </div>
    <div class="product-card__wish">
      <WishBtn :product-id="Number(product.id)" />
    </div>
    <div class="product-card__footer">
      <template v-if="product.isProductSet">
        <nuxt-link
          :to="`${ product.url }#products`"
          class="btn btn-primary"
        >
          Все предметы
        </nuxt-link>
      </template>
      <template v-if="!product.isProductSet && isHashOptions">
        <nuxt-link
          :to="product.url"
          class="btn btn-primary"
        >
          {{ labelButtonBuy }}
        </nuxt-link>
      </template>
      <template v-if="!product.isProductSet && !isHashOptions">
        <AddToCartButtonWithCounter
          :product="product as IProduct"
        />

        <button
          v-if="false"
          class="btn btn-primary"
          :class="{'--loading': isLoadBuy}"
          :disabled="isLoadBuy"
          @click="buyProduct"
        >
          {{ isLoadBuy ? 'Добавляем товар в корзину' : labelButtonBuy }}
        </button>
      </template>
    </div>

    <template v-if="discountLabel.label">
      <div
        class="product-card__discount"
        :class="{'tooltip': discountLabel.tooltip}"
      >
        <div v-html="discountLabel?.label" class="tooltip__item"/>
        <div
          v-if="discountLabel.tooltip"
          v-html="discountLabel.tooltip"
          class="tooltip__hover"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, type ComputedRef, ref } from 'vue';
import { useNuxtApp } from 'nuxt/app';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Virtual } from 'swiper/modules';
import { useIntersectionObserver } from '@vueuse/core';
import getDiscountLabel from '~/utils/getDiscountLabel';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';
import type { IWishlistItem } from '#sitis/internal/controllers/wish/models/WishlistItem';
import WishBtn from '~/components/widget/WishBtn.vue';
import { scrollToElement } from '~/utils/scrollToElement';
import AddToCartButtonWithCounter from '~/components/widget/AddToCartButtonWithCounter.vue';
import useEcommerce from '~/composables/useEcommerceYandex';
import type { IAddItemToCartBody } from '#sitis/internal/controllers/cart/models/CartItem';
import { cartStore } from '#sitis/stores/modules/cart-store';
import { getPromotionsProductCard } from '~/utils/getPromotionsProduct';
import { sortValuesBigToSmall } from '~/utils/getCharacteristic';

const TagItem = defineAsyncComponent(() => import('./collection-card/TagItem.vue'));
const TooltipSizes = defineAsyncComponent(() => import('./product-card/TooltipSizes.vue'));
const TooltipColors = defineAsyncComponent(() => import('./product-card/TooltipColors.vue'));
const ColorItemForTooltip = defineAsyncComponent(() => import('./product-card/ColorItemForTooltip.vue'));

const { addEcommerce } = useEcommerce();
const showSwiper = ref<boolean>(false);
const productImg = ref<HTMLDivElement | null>(null);

const props = defineProps<{
	product: IProduct | IWishlistItem;
	isCatalog?: boolean | undefined,
	isCollection?: boolean | undefined,
	pageProduct?: number | undefined
}>();

const $img = useImage();
const { $ym, $caseWords } = useNuxtApp();
const { $numberFormat, $toast } = useNuxtApp();
const $route = useRoute();
const $router = useRouter();
const storeCart = cartStore();

const tags = computed(() => {
  const product: any = props?.product;
  const promotions = product?.promotions || [];
  if (promotions.length <= 0) {
    return [];
  }

  return promotions
    .filter((t: any) => Boolean(t?.dynamicFields?.is_additional && t?.iconFileId))
    .sort((a: any, b: any) => {
      const sortA = a?.dynamicFields?.sort || 0;
      const sortB = b?.dynamicFields?.sort || 0;

      if (sortA < sortB) {
        return 1;
      }
      if (sortA > sortB) {
        return -1;
      }
      return 0;
    })
    .map((t: any) => {
      return {
        icon: t?.icon,
        description: t?.description || ''
      };
    });
});
const discountLabel = computed(() => getDiscountLabel(props.product));

const price = computed(() => {
  const price = Number.parseFloat(String(props?.product?.price) || '');
  return `${$numberFormat(price)} ₽`;
});

const priceOld = computed(() => {
  const price = Number.parseFloat(String(props?.product?.price) || '');
  const oldPrice = Number.parseFloat(String(props?.product?.oldPrice) || '');
  if (!oldPrice || oldPrice <= price) {
    return '';
  }

  return `${$numberFormat(oldPrice)} ₽`;
});

const imageIds = computed(() => {
  const imageIds = props?.product?.imageIds || [];
  return [...imageIds].splice(0, 4);
});

const sizeChar: any = computed(() => (props?.product?.shortCharacteristics || []).find((t: any) => (t.slug || '').includes('razmery-')));
const sizeValue = computed(() => sizeChar?.value?.values?.[0]?.value);
const sizeOtherValue = computed(() => [...sizeChar?.value?.values || []].splice(1, 999));
const sizeCount = computed(() => {
  const count = (sizeChar?.value?.values || []).length - 1;
  if (count <= 0) {
    return '';
  }
  const word = $caseWords(count, ['размер', 'размера', 'размеров']);
  return ['+', count, word].join(' ');
});

const colorsChar: any = computed(() => {
  const char = (props?.product?.shortCharacteristics || []).find((t: any) => (t.slug || '').includes('collection_color'));
  if (!char) {
    return null;
  }

  const sortValues = sortValuesBigToSmall(char);

  const brandInternal: any = (props?.product?.shortCharacteristics || []).find((t: any) => t.slug === 'brand-internal')?.values?.[0]?.value || '';
  const itemsColors = [...(sortValues?.values || [])]
    .map((_value: any) => {
      let _strValue: string = String(_value?.value || '');
      if (!!brandInternal) {
        _strValue = _strValue.replace(`${brandInternal}: `, '');
      }
      return {
        ..._value,
        value: _strValue
      }
    });

  return {
    ...sortValues,
    values: itemsColors
  }
});
const colorsValue = computed(() => [...colorsChar?.value?.values || []].splice(0, 4));
const colorsOtherValue = computed(() => [...colorsChar?.value?.values || []].splice(4, 999));
const colorsCount = computed(() => {
  const count = (colorsChar?.value?.values || []).length - 4;
  if (count <= 0) {
    return '';
  }
  const word = $caseWords(count, ['цвет', 'цвета', 'цветов']);
  return ['+', count, word].join(' ');
});

const isLoadBuy: Ref<boolean> = ref(false);
const isHashOptions: ComputedRef<boolean> = computed(() => {
  return Boolean(props.product?.hasOptions);
});
const labelButtonBuy: ComputedRef<string> = computed(() => {
  return isHashOptions.value ? 'Выбрать комплектацию' : 'В корзину';
});
const buyProduct = async () => {
  isLoadBuy.value = true;

  const productItemCart: IAddItemToCartBody = {
    productId: +props.product.id,
    quantity: 1,
    options: {}
  };
  const res: any = await storeCart.addToCart(productItemCart).then((res) => {
    return true;
  }).catch((err) => {
    return { error: err?._data };
  });
  if (res?.error) {
    isLoadBuy.value = false;
    const errorMessage = res?.error?.[0]?.message || 'Ошибка сервера';
    $toast.error(errorMessage);
    return;
  }

  if (typeof $ym === 'function') {
    $ym('reachGoal', 'addToCart');
  }
  const productAny: any = props.product;
  addEcommerce('add', productAny, { quantity: 1 });
  await storeCart.getCartItems();
  isLoadBuy.value = false;
  $toast.success('Товар добавлен в корзину');
};

const savePageProduct: Ref<number | null | undefined> = ref(null);
const clickLinkProduct = () => {
  if (props.isCatalog) {
    const { pathname } = window.location;

    const searchObj: any = $route.query || {};
    searchObj.page = savePageProduct.value;
    let searchStr: any = [];
    Object.keys(searchObj).map((searchKey: string) => {
      searchStr.push(`${searchKey}=${searchObj[searchKey]}`);
    });
    searchStr = searchStr.join('&');

    const newUrl = [pathname, searchStr].filter((t: string) => !!t).join('?');
    $router.options.history.push(newUrl);

    let coockiesData: {} | string = {
      productId: props.product.id,
      fullPath: window.location.pathname
    };
    coockiesData = JSON.stringify(coockiesData);

    const coockyCatalog: any = useCookie('cookie-scroll-catalog');
    coockyCatalog.value = coockiesData;

    return;
  }
  if (props.isCollection) {
    const slug = ($route.path || '').split('/').pop();

    let cookieConfig: any = {
      productId: props.product.id,
      collectionSlug: slug
    };
    cookieConfig = JSON.stringify(cookieConfig);

    const cookieСollection: any = useCookie('cookie-scroll-collection');
    cookieСollection.value = cookieConfig;
  }
};
const scrollToProductCatalog = () => {
  if (!props.isCatalog) {
    return;
  }

  const cookieScrollCatalog: any = useCookie('cookie-scroll-catalog');
  if (!cookieScrollCatalog.value) {
    return;
  }

  if (String(cookieScrollCatalog.value?.productId) !== String(props.product?.id)) {
    return;
  }

  const fullPath = window.location.pathname;
  if (fullPath !== cookieScrollCatalog.value?.fullPath) {
    return;
  }

  setTimeout(() => {
    scrollToElement(`[data-product-id="${props.product.id}"]`);
    cookieScrollCatalog.value = null;
  }, 501);
};
const scrollToProductCollection = () => {
  if (!props.isCollection) {
    return;
  }

  const cookieСollection: any = useCookie('cookie-scroll-collection');
  if (!cookieСollection || Boolean(String(cookieСollection.value?.productId) !== String(props.product.id))) {
    return;
  }

  const slug = ($route.path || '').split('/').pop();
  if (cookieСollection.value?.collectionSlug !== slug) {
    return;
  }

  setTimeout(() => {
    scrollToElement(`[data-product-id="${props.product.id}"]`, {
      offseetTop: 120
    });
    cookieСollection.value = null;
  }, 501);
};
onMounted(() => {
  if (props.isCatalog) {
    savePageProduct.value = props.pageProduct;
    scrollToProductCatalog();
  }
  if (props.isCollection) {
    scrollToProductCollection();
  }
});

const { stop } = useIntersectionObserver(
  productImg,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      showSwiper.value = isIntersecting;
      stop();
    }
  }
);
onBeforeUnmount(() => {
  stop();
});
</script>

<style lang="scss">
.product-card {
	border-radius: 20px;
	background-color: white;
	position: relative;
	z-index: 2;
	border: 1px solid #EBEBEB;

	& .add-toCart__mobile {
		display: none;
	}

	&:hover {
		z-index: 5;
		border-radius: 20px 20px 0 0;

		.product-card__footer {
			display: flex;
		}
	}
}

.product-card__media {
	border-radius: 20px 20px 5px 5px;
	position: relative;
	overflow: hidden;
	background: #F4F3F1;

	.swiper {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		width: 100% !important;
		background-color: #F4F3F1;
	}

	.swiper-slide img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		mix-blend-mode: multiply;
		z-index: 5;
	}

	.swiper-pagination-bullet {
		background-color: white;
		opacity: 0.5;
		width: 5px;
		height: 5px;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
	}

	&::after {
		content: "";
		float: left;
		padding: 38.53%;
	}
}

.product-card__discount {
	position: absolute;
	top: 16px;
	left: 16px;
	z-index: 5;
	border-radius: 10px;
	background: #FFF;
	box-sizing: border-box;
	color: #252525;
	font-size: 12px;
	font-weight: 600;

  .tooltip__item {
    padding: 7px 12px;
  }
  .tooltip__hover {
    background: white;
    bottom: initial;
    top: 100%;
    transform: translateY(10px);
    right: initial;
    left: 0;
    border-radius: 15px;
    border-top-left-radius: 0;

    &:after {
      top: -10px;
      bottom: initial;
      right: initial;
      left: 0;
      border-left: 15px solid white;
      border-right-color: transparent;
    }
  }
}

.product-card__body {
	padding: 24px;
	box-sizing: border-box;

	.tooltip__hover {
		z-index: 4;
    left: 20px;
    max-width: 240px;
    width: max-content;
    transform: translate(0, -15px);
    border-radius: 15px;
    border-bottom-left-radius: 0;
    &:after {
      right: initial;
      left: 0;
      top: 100%;
      transform: translateY(-10px);
      border-right: 10px solid transparent;
      border-left: 15px solid #F4F3F1;
    }
	}
}

.product-card__footer {
	display: none;
	position: absolute;
	z-index: 5;
	top: calc(100% - 3px);
	left: -1px;
	right: -1px;
	padding: 24px;
	padding-top: 0;
	background-color: white;
	border: 1px solid #EBEBEB;
	border-top: initial;
	border-radius: 0 0 20px 20px;

	& > * {
		width: 100%;
	}

	.count-info .counter {
		flex: 1;
	}

	.template-cart-btn {
		flex-direction: column;
		width: 100%;
	}

	.btn {
		width: 100%;
		height: 60px;
		font-weight: 600;
		border-radius: 16px;
	}

	.count-info {
		display: none !important;
	}
}

.product-card__name-parent {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  .tooltip {
    width: 100%;
  }
}
.product-card__name {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-weight: 600;
	font-size: 20px;
	line-height: 120%;
	color: #000000;
}

.product-card__row-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-card__tags {
	display: flex;
	flex-wrap: wrap;
	margin-top: -8px;
	margin-left: -8px;

	& > * {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 28px;
		min-width: 28px;
		margin-top: 8px;
		margin-left: 8px;
		border-radius: 7px;
		background: #EBEBEB;
	}

	.text {
		padding: 0 16px;
		color: black;
		font-size: 12px;
		line-height: 12px;
		font-weight: 500;
	}
}

.product-card__prices {
	display: flex;
	align-items: flex-end;

	.--old {
		margin-right: 6px;
		color: #989898;
		font-variant-numeric: lining-nums proportional-nums;
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
		text-decoration-line: line-through;
	}

	.--current {
		color: #000;
		font-size: 20px;
		font-weight: 600;
		line-height: 120%;
	}
}

.product-card__delimiter {
	margin: 16px 0;
	width: 100%;
	height: 1px;
	background-color: #EBEBEB;
}

.product-card__char-info {
	display: flex;
	align-items: center;

	.label-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;
		box-sizing: border-box;
		margin-right: 8px;
		width: 28px;
		height: 28px;
		border-radius: 6px;
		background: #EBEBEB;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.label-text {
		color: #000;
		font-size: 16px;
		font-weight: 500;
		line-height: 140%;
	}

	.value-text {
		margin-left: auto;
		margin-bottom: -5px;
		color: #000;
	}

	.value-colors {
		display: flex;
		margin-top: -6px;
		margin-left: auto;

		.image-item {
			width: 28px;
			height: 28px;
			margin-top: 6px;
			margin-left: 6px;
			position: relative;
			border-radius: 7px;
			overflow: hidden;
			border: 1px solid #989898;

			img {
				position: absolute;
				top: -4px;
				left: -4px;
				width: calc(100% + 8px);
				height: calc(100% + 8px);
				max-width: initial;
				max-height: initial;
				object-fit: cover;
			}
		}
	}

	.value-button {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 28px;
		border-radius: 4px;
		border: 0.5px solid rgb(0 0 0 / 10%);
		margin-left: 10px;
		padding: 0 7px;
		box-sizing: border-box;
		color: #000;
		font-variant-numeric: lining-nums proportional-nums;
		font-size: 12px;
		font-weight: 500;
		line-height: 12px;
	}

	&.mt {
		margin-top: 12px;
	}
}

.product-card__wish {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 9;
	top: 16px;
	right: 16px;
	width: 38px;
	height: 38px;
	background-color: white;
	cursor: pointer;
	border-radius: 10px;
}

@media (max-width: 1439px) and (min-width: 1360px) {
	.product-card__char-info .label-text,
	.product-card__char-info .value-text {
		font-size: 14px;
	}
	.product-card__char-info .value-button {
		margin-left: 6px;
	}
}

@media (min-width: 1024px) {
	.product-card:not(:hover) {
		.product-card__media {
			.swiper-button-prev, .swiper-button-next {
				display: none;
			}
		}
	}
}

@media (max-width: 1024px) {
	.product-card {
		display: flex;
		flex-direction: column;

		border-radius: 20px !important;

		& .add-toCart__mobile {
			display: flex;
			width: 100%;
			min-height: 48px;
			margin-top: auto;
			padding-top: 24px;

			& > div {
				width: 100%;
			}

			& .btn {
				width: 100%;
				font-weight: 600;
				border-radius: 12px;
				height: 48px;
				background: var(--dark-gray);
			}

			& .count-info {
				height: 48px;
			}
		}

	}
	.product-card__body {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.product-card__footer {
		display: none !important;
	}
}

@media (max-width: 1023px) {
	.add-toCart__mobile .count-info {
		display: none !important;
	}
	.product-card__prices {
		flex-direction: column;
		white-space: nowrap;

		.--old {
			margin-right: 0;
			line-height: 100%;
		}
	}
	.product-card__char-info {
		.label-icon, .label-text {
			display: none;
		}

		.value-text, .value-colors {
			margin-left: 0;
			margin-right: auto;
		}

		.value-colors {
			margin-left: -6px;
		}
	}
}
</style>
